<template>

    <div class="main" style="position: fixed;min-width: 700px !important;max-width: 100% ">
        <div class="left" style="overflow-x: hidden; padding-right: 500px;padding-left: 10px;top: 49px;height: calc(100vh - 79px);">
            <v-row>
                <v-col cols="12">
                    <div>
                        <div class="text-h3">
                            收银清单
                        </div>
                        <v-divider></v-divider>
                        <v-card
                                class="mx-auto card"
                        >
                            <v-card-title>
                                <v-icon
                                        large
                                        left
                                >
                                    mdi-home-floor-b
                                </v-icon>
                                <span class="title font-weight-light">基础信息</span>
                            </v-card-title>
                            <v-row>
                                <v-col md="3" cols="12" sm="12">
                                    <v-menu
                                            ref="menu"
                                            :close-on-content-click="false"
                                            :return-value.sync="date"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                    v-model="date"
                                                    label="日期"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    dense
                                                    outlined
                                                    v-bind="attrs"
                                                    v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                                v-model="date"
                                                no-title
                                                scrollable
                                                @input="$refs.menu.save(date);"
                                        >
                                            <!--                            <v-spacer></v-spacer>-->
                                            <!--                            <v-btn-->
                                            <!--                                    text-->
                                            <!--                                    color="primary"-->
                                            <!--                                    @click="() => {$refs.menu.save(date);}"-->
                                            <!--                            >-->
                                            <!--                                Cancel-->
                                            <!--                            </v-btn>-->
                                            <!--                            <v-btn-->
                                            <!--                                    text-->
                                            <!--                                    color="primary"-->
                                            <!--                                    @click="$refs.menu.save(date)"-->
                                            <!--                            >-->
                                            <!--                                OK-->
                                            <!--                            </v-btn>-->
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col md="3" cols="12" sm="12">
                                    <v-text-field
                                            outlined
                                            dense
                                            label="收银单序列号"
                                    ></v-text-field>
                                </v-col>
                                <v-col md="3" cols="12" sm="12">
                                    <v-text-field
                                            outlined
                                            dense
                                            label="合同编号"
                                    ></v-text-field>
                                </v-col>
                                <v-col md="3" cols="12" sm="12">
                                    <v-text-field
                                            outlined
                                            dense
                                            label="供货方"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card>
                        <v-card
                                class="mx-auto card"
                        >
                            <v-card-title>
                                <v-icon
                                        large
                                        left
                                >
                                    mdi-account
                                </v-icon>
                                <span class="title font-weight-light">客户信息</span>
                            </v-card-title>
                            <v-row>

                                <v-col md="3" cols="12" sm="12">
                                    <v-radio-group
                                            v-model="client_mode"
                                            row
                                    >
                                        <v-radio
                                                label="个人"
                                                :value="1"
                                        ></v-radio>
                                        <v-radio
                                                label="企业"
                                                :value="0"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col md="3" cols="12" sm="12">
                                    <v-text-field
                                            outlined
                                            dense
                                            label="购买方"
                                    ></v-text-field>
                                </v-col>
                                <v-col md="3" cols="12" sm="12">
                                    <v-text-field
                                            outlined
                                            dense
                                            :label="client_mode ? '购买方证件号码' : '购买方营业执照'"
                                    ></v-text-field>
                                </v-col>
                                <v-col md="3" cols="12" sm="12">
                                    <v-text-field
                                            outlined
                                            dense
                                            label="邮政编码"
                                    ></v-text-field>
                                </v-col>
                                <v-col md="3" cols="12" sm="12">
                                    <v-text-field
                                            outlined
                                            dense
                                            label="联系人"
                                    ></v-text-field>
                                </v-col>
                                <v-col md="3" cols="12" sm="12">
                                    <v-text-field
                                            outlined
                                            dense
                                            label="联系电话"
                                    ></v-text-field>
                                </v-col>

                                <v-col md="12" cols="12" sm="12">
                                    <v-textarea
                                            outlined
                                            rows="3"
                                            dense
                                            label="通讯地址"
                                            value=""
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                        </v-card>
                        <v-card
                                class="mx-auto card"
                        >
                            <v-card-title>
                                <v-icon
                                        large
                                        left
                                >
                                    mdi-car-back
                                </v-icon>
                                <span class="title font-weight-light">车辆信息</span>
                            </v-card-title>
                            <v-row>
                                <v-col md="3" cols="12" sm="12">
                                    <v-text-field
                                            outlined
                                            dense
                                            @input="changeVin"
                                            label="VIN码"
                                    ></v-text-field>
                                </v-col>
                                <v-col md="3" cols="12" sm="12">
                                    <v-text-field
                                            outlined
                                            dense
                                            :value="car_info.goods_name"
                                            label="商品名称"
                                            disabled
                                    ></v-text-field>
                                </v-col>
                                <v-col md="3" cols="12" sm="12">
                                    <v-text-field
                                            outlined
                                            dense
                                            :value="car_info.model"
                                            label="商品型号"
                                            disabled
                                    ></v-text-field>
                                </v-col>
                                <v-col md="3" cols="12" sm="12">
                                    <v-text-field
                                            outlined
                                            dense
                                            :value="car_info.color"
                                            label="车身颜色"
                                            disabled
                                    ></v-text-field>
                                </v-col>
                                <v-col md="3" cols="12" sm="12">
                                    <v-text-field
                                            outlined
                                            dense
                                            :value="car_info.number_id"
                                            label="合格证号"
                                            disabled
                                    ></v-text-field>
                                </v-col>
                                <v-col md="3" cols="12" sm="12">
                                    <v-text-field
                                            outlined
                                            dense
                                            :value="car_info.engine_id"
                                            label="发动机号"
                                            disabled
                                    ></v-text-field>
                                </v-col>
                                <v-col md="3" cols="12" sm="12">
                                    <v-text-field
                                            outlined
                                            dense
                                            :value="car_info.car_price"
                                            label="车价"
                                            disabled
                                    ></v-text-field>
                                </v-col>
                                <v-col md="3" cols="12" sm="12">
                                    <v-text-field
                                            outlined
                                            dense
                                            :value="car_info.tax"
                                            label="购置税"
                                            disabled
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card>
                        <v-card
                                class="mx-auto card"
                                style="position: relative"
                        >
                            <v-btn
                                    @click="open_edit"
                                    style="position: absolute; right: -10px; top: -10px"
                                    class="mx-2"
                                    fab
                                    dark
                                    small
                                    color="#787878"
                            >
                                <v-icon dark>
                                    mdi-pencil
                                </v-icon>
                            </v-btn>
                            <v-card-title>
                                <v-icon
                                        large
                                        left
                                >
                                    mdi-hexagram
                                </v-icon>
                                <span class="title font-weight-light">保险费</span>
                            </v-card-title>
                            <v-row>
                                <v-col md="3" cols="12" sm="12">
                                    <v-text-field
                                            outlined
                                            dense
                                            v-model="insurance_info.field_1"
                                            disabled
                                            label="商业险金额"
                                    ></v-text-field>
                                </v-col>
                                <v-col md="3" cols="12" sm="12">
                                    <v-text-field
                                            outlined
                                            disabled
                                            dense
                                            v-model="insurance_info.field_2"
                                            label="交强险金额"
                                    ></v-text-field>
                                </v-col>
                                <v-col md="3" cols="12" sm="12">
                                    <v-text-field
                                            outlined
                                            dense
                                            v-model="insurance_info.field_3"
                                            disabled
                                            label="车船税"
                                    ></v-text-field>
                                </v-col>
                                <v-col md="3" cols="12" sm="12">
                                    <v-text-field
                                            outlined
                                            disabled
                                            dense
                                            v-model="insurance_info.field_4"
                                            label="保险公司"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card>
                        <v-card
                                class="mx-auto card"
                        >
                            <v-card-title>
                                <v-icon
                                        large
                                        left
                                >
                                    mdi-quality-high
                                </v-icon>
                                <span class="title font-weight-light">精品套餐</span>
                            </v-card-title>
                            <v-row>
                                <v-col md="11" cols="11" sm="11">
                                    <v-autocomplete
                                            outlined
                                            dense
                                            v-model="accessories_value"
                                            label="精品套餐"
                                            :items="accessories"
                                            @keypress.enter="add_accessories"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col md="1" cols="1" sm="1">
                                    <v-btn
                                            @click="add_accessories"
                                    >
                                        添加
                                    </v-btn>
                                </v-col>
                                <v-col v-if="accessories_list.length > 0" md="12" cols="12" sm="12">
                                    <v-simple-table style="border: 1px solid #ddd">
                                        <template v-slot:default>
                                            <thead>
                                            <tr>
                                                <th class="text-left">
                                                    名称
                                                </th>
                                                <th class="text-left">
                                                    价格
                                                </th>
                                                <th class="text-left">
                                                    操作
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr
                                                    v-for="(item,index) in accessories_list"
                                                    :key="item.name"
                                            >
                                                <td>{{ item.goods_name }}</td>
                                                <td>{{ item.price }}元</td>
                                                <td><span @click="delAccessories(index)"
                                                          style="color: #3d5afe; cursor: pointer">删除</span></td>
                                            </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-col>
                            </v-row>
                        </v-card>
                        <v-card
                                class="mx-auto card"
                        >
                            <v-row>
                                <v-col>
                                    <v-text-field
                                            outlined
                                            dense
                                            label="咨询服务费（含续保押金）"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card>
                        <v-card
                                class="mx-auto card"
                        >
                            <v-card-title>
                                <v-icon
                                        large
                                        left
                                >
                                    mdi-file-account-outline
                                </v-icon>
                                <span class="title font-weight-light">其他代办费用合计																</span>
                            </v-card-title>
                            <v-row>
                                <v-col md="3" cols="12" sm="12">
                                    <v-text-field
                                            outlined
                                            dense
                                            label="上牌费用"
                                    ></v-text-field>
                                </v-col>
                                <v-col md="3" cols="12" sm="12">
                                    <v-text-field
                                            outlined
                                            dense
                                            label="出库费"
                                    ></v-text-field>
                                </v-col>
                                <v-col md="3" cols="12" sm="12">
                                    <v-text-field
                                            outlined
                                            dense
                                            label="验车收入"
                                    ></v-text-field>
                                </v-col>
                                <v-col md="3" cols="12" sm="12">
                                    <v-text-field
                                            outlined
                                            dense
                                            label="玻璃保障卡"
                                    ></v-text-field>
                                </v-col>
                                <v-col md="3" cols="12" sm="12">
                                    <v-text-field
                                            outlined
                                            dense
                                            label="客户综合权益"
                                    ></v-text-field>
                                </v-col>
                                <v-col md="3" cols="12" sm="12">
                                    <v-text-field
                                            outlined
                                            dense
                                            label="刷卡手续费"
                                    ></v-text-field>
                                </v-col>
                                <v-col md="3" cols="12" sm="12">
                                    <v-text-field
                                            outlined
                                            dense
                                            label="颜色"
                                    ></v-text-field>
                                </v-col>
                                <v-col md="3" cols="12" sm="12">
                                    <v-text-field
                                            outlined
                                            dense
                                            label="红包"
                                    ></v-text-field>
                                </v-col>
                                <v-col md="3" cols="12" sm="12">
                                    <v-text-field
                                            outlined
                                            dense
                                            label="漆面保障卡"
                                    ></v-text-field>
                                </v-col>
                                <v-col md="3" cols="12" sm="12">
                                    <v-text-field
                                            outlined
                                            dense
                                            label="保养套餐"
                                    ></v-text-field>
                                </v-col>
                                <v-col md="3" cols="12" sm="12">
                                    <v-text-field
                                            outlined
                                            dense
                                            label="临牌费"
                                    ></v-text-field>
                                </v-col>
                                <v-col md="3" cols="12" sm="12">
                                    <v-text-field
                                            outlined
                                            dense
                                            label="天窗保障卡"
                                    ></v-text-field>
                                </v-col>
                                <v-col md="3" cols="12" sm="12">
                                    <v-text-field
                                            outlined
                                            dense
                                            label="车辆管理费"
                                    ></v-text-field>
                                </v-col>
                                <v-col md="3" cols="12" sm="12">
                                    <v-text-field
                                            outlined
                                            dense
                                            label="综合服务费"
                                    ></v-text-field>
                                </v-col>
                                <v-col md="3" cols="12" sm="12">
                                    <v-text-field
                                            outlined
                                            dense
                                            label="档案管理费"
                                    ></v-text-field>
                                </v-col>
                                <v-col md="3" cols="12" sm="12">
                                    <v-text-field
                                            outlined
                                            dense
                                            label="轮胎保障卡"
                                    ></v-text-field>
                                </v-col>
<!--                                <v-col md="3" cols="12" sm="12">-->
<!--                                    <v-text-field-->
<!--                                            outlined-->
<!--                                            dense-->
<!--                                            label="收款总金额"-->
<!--                                    ></v-text-field>-->
<!--                                </v-col>-->
                            </v-row>
                        </v-card>
                        <v-card
                                class="mx-auto card"
                        >
                            <v-row>
                                <v-col md="3" cols="12" sm="12">
                                    <v-text-field
                                            outlined
                                            dense
                                            label="已付订金"
                                    ></v-text-field>
                                </v-col>
                                <v-col md="3" cols="12" sm="12">
                                    <v-text-field
                                            outlined
                                            dense
                                            label="已付其他费用"
                                    ></v-text-field>
                                </v-col>
                                <v-col md="3" cols="12" sm="12">
                                    <v-text-field
                                            outlined
                                            dense
                                            label="实际收款总金额"
                                    ></v-text-field>
                                </v-col>
                                <v-col md="3" cols="12" sm="12">
                                    <v-text-field
                                            outlined
                                            dense
                                            label="含税开票金额"
                                    ></v-text-field>
                                </v-col>
                                <v-col md="3" cols="12" sm="12">
                                    <v-autocomplete
                                            outlined
                                            :items="['小蓝', '小红', '小白', '小黑']"
                                            dense
                                            label="销售顾问"
                                    ></v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-card>
                        <v-row>
                            <v-col md="3" cols="12" sm="12">
                                <v-btn
                                        depressed
                                        color="primary"
                                >
                                    提交
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
        </div>
        <div class="right">
            <v-col cols="12" class="text-h6 right__subtitle" style="margin-top: 0px">
                基本信息
            </v-col>
            <v-divider></v-divider>
            <div class="right__content">
                <div class="right__content__subtitle">
                    日期： 2020年12月28日
                </div>
                <div class="right__content__subtitle">
                    收银单序列号：
                </div>
                <div class="right__content__subtitle">
                    合同编号：
                </div>
                <div class="right__content__subtitle">
                    供货方：
                </div>
            </div>
            <div class="text-h6 right__subtitle">
                客户信息
            </div>
            <v-divider></v-divider>
            <div class="right__content">
                <div class="right__content__subtitle">
                    客户类型： 个人
                </div>
                <div class="right__content__subtitle">
                    购买方：
                </div>
                <div class="right__content__subtitle">
                    购买方证件号码：
                </div>
                <div class="right__content__subtitle">
                    通讯地址：
                </div>
                <div class="right__content__subtitle">
                    邮政编码：
                </div>
                <div class="right__content__subtitle">
                    联系人：
                </div>
                <div class="right__content__subtitle">
                    联系电话：
                </div>
            </div>
            <div class="text-h6 right__subtitle">
                车辆信息
            </div>
            <v-divider></v-divider>
            <div class="right__content">
                <div class="right__content__subtitle">
                    商品名称：
                </div>
                <div class="right__content__subtitle">
                    商品型号：
                </div>
                <div class="right__content__subtitle">
                    车身颜色：
                </div>
                <div class="right__content__subtitle">
                    VIN码：
                </div>
                <div class="right__content__subtitle">
                    合格证号：
                </div>
                <div class="right__content__subtitle">
                    发动机号：
                </div>
                <div class="right__content__subtitle">
                    车价：
                </div>
                <div class="right__content__subtitle">
                    购置税：
                </div>
            </div>
            <div class="text-h6 right__subtitle">
                保险费
            </div>
            <v-divider></v-divider>
            <div class="right__content">
                <div class="right__content__subtitle">
                    商业险金额：
                </div>
                <div class="right__content__subtitle">
                    交强险金额：
                </div>
                <div class="right__content__subtitle">
                    车船税：
                </div>
                <div class="right__content__subtitle">
                    保险公司：
                </div>
            </div>
            <div class="text-h6 right__subtitle">
                精品套餐
            </div>
            <v-divider></v-divider>
            <div class="right__content">
                <div class="right__content__subtitle">
                    <v-icon
                            style="cursor: pointer; "
                            large
                            @click="show_accessories_list = true"
                            v-if="accessories_list.length > 0 && !show_accessories_list"
                    >
                        mdi-playlist-plus
                    </v-icon>
                    <v-icon
                            style="cursor: pointer; "
                            large
                            @click="show_accessories_list = false"
                            v-if="accessories_list.length > 0 && show_accessories_list"
                    >
                        mdi-server-minus
                    </v-icon>
                    {{ accessories_list.length <= 0 ? '请添加' : ''}}
                    {{ (accessories_list.length > 0)? show_accessories_list ? '点击隐藏' : '点击展开' : ''}}
                </div>
                <v-simple-table style="border: 1px solid #ddd; background: none;" v-if="accessories_list.length > 0 && show_accessories_list">
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <th class="text-left">
                                名称
                            </th>
                            <th class="text-left">
                                价格
                            </th>
<!--                            <th class="text-left">-->
<!--                                操作-->
<!--                            </th>-->
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                                v-for="(item,index) in accessories_list"
                                :key="item.name"
                        >
                            <td>{{ item.goods_name }}</td>
                            <td>{{ item.price }}元</td>
<!--                            <td><span @click="delAccessories(index)"-->
<!--                                      style="color: #3d5afe; cursor: pointer">删除</span></td>-->
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </div>
            <div class="text-h6 right__subtitle">
                其他代办费用合计
            </div>
            <v-divider></v-divider>
            <div class="right__content">
                <div class="right__content__subtitle">
                    上牌费用：
                </div>
                <div class="right__content__subtitle">
                    出库费：
                </div>
                <div class="right__content__subtitle">
                    验车收入：
                </div>
                <div class="right__content__subtitle">
                    玻璃保障卡：
                </div>
                <div class="right__content__subtitle">
                    客户综合权益：
                </div>
                <div class="right__content__subtitle">
                    刷卡手续费：
                </div>
                <div class="right__content__subtitle">
                    颜色：
                </div>
                <div class="right__content__subtitle">
                    红包：
                </div>
                <div class="right__content__subtitle">
                    漆面保障卡：
                </div>
                <div class="right__content__subtitle">
                    保养套餐：
                </div>
                <div class="right__content__subtitle">
                    临牌费：
                </div>
                <div class="right__content__subtitle">
                    天窗保障卡：
                </div>
                <div class="right__content__subtitle">
                    车辆管理费：
                </div>
                <div class="right__content__subtitle">
                    综合服务费：
                </div>
                <div class="right__content__subtitle">
                    档案管理费：
                </div>
                <div class="right__content__subtitle">
                    轮胎保障卡：
                </div>
                <div class="right__content__subtitle">
                    收款总金额：
                </div>
            </div>
            <div class="text-h6 right__subtitle">
                结算
            </div>
            <v-divider></v-divider>
            <div class="right__content">
                <div class="right__content__subtitle" style="margin-top: 20px">
                    已付订金：<span class="right__content__subtitle__price">¥ 1000.00</span>
                </div>
                <div class="right__content__subtitle" style="margin-top: 20px">
                    已付其他费用：<span class="right__content__subtitle__price">¥ 1000.00</span>
                </div>
                <div class="right__content__subtitle" style="margin-top: 20px">
                    实际收款总金额：<span class="right__content__subtitle__price">¥ 1000.00</span>
                </div>
                <div class="right__content__subtitle" style="margin-top: 20px">
                    含税开票金额：<span class="right__content__subtitle__price">¥ 1000.00</span>
                </div>
                <div class="right__content__subtitle" style="margin-top: 20px">
                    销售顾问：<span class="right__content__subtitle__price">¥ 1000.00</span>
                </div>
            </div>

        </div>
        <Dialog
                title="修改保险费"
                :dialog="dialog"
                @clickOk="handleClickOk"
                @cancel="cancel"
        >
            <template v-slot:content>
                <v-row>
                    <v-col md="12" cols="12" sm="12">
                        <v-text-field
                                outlined
                                dense
                                v-model="temp_insurance_info.field_1"
                                label="商业险金额"
                        ></v-text-field>
                    </v-col>
                    <v-col md="12" cols="12" sm="12">
                        <v-text-field
                                outlined
                                dense
                                v-model="temp_insurance_info.field_2"
                                label="交强险金额"
                        ></v-text-field>
                    </v-col>
                    <v-col md="12" cols="12" sm="12">
                        <v-text-field
                                outlined
                                dense
                                v-model="temp_insurance_info.field_3"
                                label="车船税"
                        ></v-text-field>
                    </v-col>
                    <v-col md="12" cols="12" sm="12">
                        <v-text-field
                                outlined
                                dense
                                v-model="temp_insurance_info.field_4"
                                label="保险公司"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </template>
        </Dialog>
    </div>
</template>

<script>
    import car_info_list, {accessories} from './mock.js'
    import Dialog from '@/components/dialog'
    export default {
        name: 'listIndex',
        data: () => ({
            dialog: false,
            temp_insurance_info: {
                field_1: '',
                field_2: '',
                field_3: '',
                field_4: '',
            },
            insurance_info: {
                field_1: '',
                field_2: '',
                field_3: '',
                field_4: '',
            },
            show_accessories_list: false,
            car_info_list,
            accessories,
            car_info: {
                goods_name: '',
                model: '',
                color: '',
                number_id: '',
                engine_id: '',
                car_price: '',
                tax: ''
            },
            accessories_value: '',
            accessories_list: [],
            date: '',
            client_mode: 1
        }),
        methods: {
            cancel() {
                this.dialog = false
            },
            open_edit() {
                this.dialog  =true
            },
            handleClickOk() {
                this.dialog =false
                this.insurance_info = this.temp_insurance_info
            },
            changeVin(e) {
                if (this.car_info_list[e]) {
                    console.log('enter', this.car_info_list[e].color)
                    this.car_info = this.car_info_list[e].car_info
                }
            },
            add_accessories(e) {
                // console.log(e,this.accessories_value)
                if (this.accessories_value === '')
                    return;

                this.accessories_list.push({
                    goods_name: this.accessories_value,
                    price: (Math.random() * (100 - 10 + 1) + 10).toFixed(2)
                })
                this.accessories_value = '';
                // console.log(this.accessories_list)
            },
            delAccessories(index) {
                this.accessories_list.splice(index, 1)
            }
        }
        ,
        components: {
            Dialog
        }
    }
</script>
<style lang="stylus" scoped>
    @font-face
        font-family 'heiti';
        src url("https://spwx.oss-cn-beijing.aliyuncs.com/001.ttf")
    .main
        padding: 20px
        font-family heiti
        .left
            &::-webkit-scrollbar
                display none
        .card
            padding: 20px;
            margin-top: 30px;
        .right
            position: fixed;
            right: 0px;
            width: 480px
            background: #f4f4f4;
            top: 49px;
            height calc(100vh - 79px);
            padding 10px;
            overflow-y scroll
            &::-webkit-scrollbar
                display none
            &__subtitle
                margin-top: 10px;
                padding: 0px;
                /*font-size 12px;*/
                /*text-align center;*/
                color #333;
            &__content
                padding-left 10px
                &__subtitle
                    color: #898989;
                    font-size 15px;
                    margin-top: 6px;
                    &__price
                        font-size 22px;
                        color #333;
                        position: absolute;
                        right 30px
</style>
