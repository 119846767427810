<template>
    <v-dialog
            v-model="dialog"
            persistent
            max-width="600px"
    >
        <v-card>
            <v-card-title>
                <span class="headline">{{title}}</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <slot name="content"></slot>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                        color="blue darken-1"
                        text
                        v-if="showCancle"
                        @click="close"
                >
                    取消
                </v-btn>
                <v-btn
                        color="blue darken-1"
                        text
                        @click="clickOk"
                >
                    确认
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    export default {
        name: 'Dialog',
        props: {
            title: {
                default: '默认标题',
                type: String
            },
            showCancle: {
                default: true,
                type: Boolean
            },
            dialog: {
                default: true,
                type: Boolean
            }

        },
        methods: {
            close() {
                this.$emit('cancel')
            },
            clickOk() {
                this.$emit('clickOk');
            }
        }
    }
</script>
