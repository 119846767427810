export default {
    'A001': {
        car_info: {
            goods_name: '大众',
            model: '奔腾T77',
            color: '红色',
            number_id: '100201023022',
            engine_id: '230123001244',
            car_price: '12~23万',
            tax: '23%'
        }
    }
}
export const accessories = [
    '发动机缸盖',
    '缸盖螺栓',
    '机油导流板',
    '机油口盖',
    '气门油封',
    '发动机支架胶垫',
    '汽缸体',
    '空气滤清器',
    '消声器吊胶',
    '空滤器缓冲胶',
    '进气管',
    '消声器',
    '排气管弹簧',
    '排气管螺栓',
    '消声器夹',
    '怠速控制阀',
    '废气再循环阀',
    '涡轮增压器',
    '节气门',
    '气门挺杆',
    '气门摇臂',
    '气门导管',
    '时规导轨',
    '正时链条',
    '正时齿轮',
    '进气门',
    '排气门',
    '三元催化器',
    '曲轴',
    '连杆',
    '轴瓦',
    '止推片',
    '曲轴油封',
    '缸套',
    '活塞环',
    '活塞',
    '活塞修理包',
    '通风管',
    '凸齿轮齿',
    '凸齿轮',
    '曲轴齿轮',
    '飞轮',
    '机油泵驱动齿轮',
    '活塞销',
    '机油滤清器',
    '机油泵',
    '油底壳',
    '机油尺导管',
    '机油冷却器',
    '排油塞',
    '机油泵驱动链条',
    '机油滤芯',
    '油门拉线',
    '空气流量计',
    '化油器',
    '化油器法兰',
    '汽油滤清器',
    '燃油传感器',
    '汽油泵',
    '油箱盖',
    '止动泵',
    '油管',
    '喷嘴',
    '燃油压力调节器',
    '汽油分离器',
    '滤网',
    '油门踏板',
    '离合器压盘',
    '离合器片',
    '分离轴承',
    '离合总泵',
    '离合分泵',
    '离合拉线',
    '离合拉线套管',
    '分离轴',
    '离合踏板轴',
    '离合总泵修理包',
    '离合分泵修理包',
    '离合轴承座',
]
